import React, { useState } from "react";
import Geocode from "react-geocode";
import Backdrop from "@mui/material/Backdrop";

import Settings from "../settings";
import DetailView from "../../Buttons/DetailView";
import CreateOrCloneView from "../../Buttons/CreateOrCloneView";
import ListViewWithoutRecord from "../../Buttons/ListViewWithoutRecord";
import ListView from "../../Buttons/ListView/index";

import Loader from "../loader/Loader";

export default function Buttons({
  ZOHO,
  api_key,
  moduleInfo,
  dataCenterUrl,
  listViewWithoutRecordInfo,
  fetchedInfo,
  show_settings_btn,
  fetchedModules,
  modules,
  handleFetchedModules,
  fetchDataOfThatModule,
  orgId,
  zohoApiKey,
  zapiKey,
}) {
  const [map, setMap] = useState(null);

  //Google api
  const api = api_key;

  //Geocode Pacakge Setup
  Geocode.setApiKey(api_key);
  Geocode.setLocationType("ROOFTOP");
  Geocode.setLanguage(fetchedInfo.setting_schema?.language?.code || "en");

  Geocode.enableDebug(true);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (show_settings_btn) {
    return (
      <Settings
        modules={modules}
        fetchedModules={fetchedModules}
        fetchDataOfThatModule={fetchDataOfThatModule}
        handleFetchedModules={handleFetchedModules}
        api_key={api}
        for_settings={false}
        module_for_buttons={{
          plural_label: moduleInfo.plural_label,
          api_name: moduleInfo.module_name,
        }}
        zohoApiKey={zohoApiKey}
        orgId={orgId}
      />
    );
  }

  if (!moduleInfo)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <Loader />
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    );

  if (
    moduleInfo.buttonPosition === "CreateOrCloneView" ||
    moduleInfo.buttonPosition === "EditView"
  ) {
    return (
      <CreateOrCloneView
        moduleInfo={moduleInfo}
        fetchedInfo={fetchedInfo}
        ZOHO={ZOHO}
        apiKey={api}
        saveButtonLabel="Populate"
      />
    );
  }
  if (
    moduleInfo.buttonPosition === "DetailView" ||
    moduleInfo.buttonPosition === "ListViewEachRecord"
  ) {
    return (
      <DetailView
        moduleInfo={moduleInfo}
        fetchedInfo={fetchedInfo}
        ZOHO={ZOHO}
        apiKey={api}
        onUnmount={onUnmount}
        saveButtonLabel="Save"
      />
    );
  }

  if (
    moduleInfo.buttonPosition === "ListViewWithoutRecord" &&
    listViewWithoutRecordInfo
  ) {
    return (
      <ListViewWithoutRecord
        zapiKey={zapiKey}
        moduleInfo={moduleInfo}
        listViewWithoutRecordInfo={listViewWithoutRecordInfo}
        dataCenterUrl={dataCenterUrl}
        fetchedInfo={fetchedInfo}
        apiKey={api}
        ZOHO={ZOHO}
      />
    );
  }

  if (moduleInfo.buttonPosition === "ListView") {
    return (
      <ListView
        moduleInfo={moduleInfo}
        fetchedInfo={fetchedInfo}
        apiKey={api}
        ZOHO={ZOHO}
      />
    );
  }
}
