import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import produce from "immer";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import CustomAutoComplete from "../../Custom_AutoComplete";
import fields from "./fields.json";
import AddressBlockTableHead from "./AddressBlockTableHead";
import ModalForDeleteBlock from "./ModalForDeleteBlock";
import { generateNewAddress } from "..";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Index = ({
  handleStartPage,
  handleClose,
  handleUpdateSettings,
  fetchedModules,
  data,
  setData,
  existingData,
  for_settings,
  module_for_buttons,
  orgId,
  zohoApiKey,
}) => {
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = React.useState(`panel_0`);
  const [open, setOpen] = React.useState(false);
  const [currentBlock, setCurrentBlock] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleCloseNestedModal = () => setOpen(false);

  function customTrim(str, chars) {
    let start = 0;
    let end = str.length;

    while (start < end && chars.includes(str[start])) {
      start++;
    }

    while (end > start && chars.includes(str[end - 1])) {
      end--;
    }

    return str.slice(start, end);
  }

  const handleSnackBarOpen = () => {
    setSnackBarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  function isErrorFound() {
    const emptyRequiredFieldFound = data.blocks.some((block) => {
      if (!block.address_block_name) {
        return true;
      }
      let isRequiredFieldEmpty = false;
      Object.values(block.fieldMapping).forEach((itm) => {
        if (itm.required && !itm.selected_field?.api_name) {
          isRequiredFieldEmpty = true;
        }
      });
      if (isRequiredFieldEmpty) return true;
    });
    return emptyRequiredFieldFound;
  }

  const handleChange = (panel) => (event, isExpanded) => {
    const errorFound = isErrorFound();

    if (errorFound) {
      handleSnackBarOpen();
      return;
    }

    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    setExpanded(`panel_${data.blocks.length - 1}`);
  }, [data.blocks.length]);

  return (
    <Box sx={{ py: "3.5rem", mx: "25px" }}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Please fill in all required fields!
        </Alert>
      </Snackbar>
      <Grid
        container
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          pl: 3,
          py: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "#ffffff",
          zIndex: 3,
        }}
      >
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <ArrowBackIcon
            sx={{ color: "rgba(0, 0, 0, 0.54)", cursor: "pointer" }}
            onClick={handleStartPage}
          />
        </Grid>

        <Grid item>
          <Button
            sx={{
              fontWeight: 500,
              color: "rgba(25, 118, 210, 1)",
              mr: 5,
              textTransform: "capitalize",
            }}
            size="small"
            variant="outlined"
            onClick={() => {
              const errorFound = isErrorFound();

              if (errorFound) {
                handleSnackBarOpen();
                return;
              }

              setData((prev) => ({
                ...prev,
                blocks: [
                  ...prev.blocks,
                  generateNewAddress(`Address-${data.blocks.length + 1}`),
                ],
              }));
            }}
          >
            + Add Address Block
          </Button>
        </Grid>
      </Grid>

      <Box sx={{ mx: "auto" }}>
        {data.blocks.map((blockData, blockIndex) => (
          <Accordion
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: "8px",
              boxShadow: "none",
              mb: -1,
            }}
            key={blockIndex}
            expanded={expanded === `panel_${blockIndex}`}
            onChange={handleChange(`panel_${blockIndex}`)}
          >
            {data.blocks.length === 1 ||
            expanded === `panel_${blockIndex}` ? null : (
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  {blockData.address_block_name || `Address-${blockIndex + 1}`}{" "}
                </Typography>
              </AccordionSummary>
            )}

            <AccordionDetails sx={{ p: 0 }}>
              <Box
                component="form"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  my: 1,
                  px: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ mr: 2 }}>
                    Block Name <span style={{ color: "red" }}>*</span>
                  </Typography>

                  <TextField
                    error={!blockData.address_block_name ? "true" : ""}
                    size="small"
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ autocomplete: "off" }}
                    value={blockData.address_block_name || ""}
                    onChange={(e) =>
                      setData(
                        produce((draft) => {
                          draft.blocks[blockIndex].address_block_name =
                            e.target.value;
                        })
                      )
                    }
                  />
                  <Typography variant="p" sx={{ ml: 5 }}>
                    You can change the block name
                  </Typography>
                </Box>
                {data.blocks.length === 1 ? null : (
                  <Button
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      alignSelf: "flex-start",
                      fontSize: "14px",
                      height: 40,
                      color: "rgba(211, 47, 47, 1)",
                      backgroundColor: "#fff",
                      outline: "1px solid red",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      "&:hover": {
                        background: "rgba(211, 47, 47, 1)",
                        color: "#ffffff",
                      },
                      "&:hover>svg": {
                        color: "#ffffff",
                      },
                      textTransform: "capitalize",
                    }}
                    size="small"
                    onClick={() => {
                      handleOpen();
                      setCurrentBlock(blockIndex);
                    }}
                  >
                    <DeleteOutlineOutlinedIcon
                      sx={{
                        color: "rgba(211, 47, 47, 1)",
                      }}
                    />
                    <span>delete address block</span>
                  </Button>
                )}
              </Box>

              <AddressBlockTableHead />
              <Grid
                container
                spacing={1}
                sx={{
                  py: 1,
                }}
              >
                {[
                  "address_1",
                  "address_2",
                  "city",
                  "state",
                  "zip",
                  "country",
                  "latitude",
                  "longitude",
                ].map((address_key, itmIndex) => {
                  const itm = blockData.fieldMapping?.[address_key];
                  return (
                    <>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "0 .75rem",
                          // pb:
                          //   Object.values(blockData.fieldMapping).length - 1 !==
                          //     itmIndex && 0,
                        }}
                        xs={9}
                      >
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            LineHeight: "20.02px",
                            letterSpacing: ".17px",
                            color: "rgba(0, 0, 0, 0.6)",
                            flex: 0.7,
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            ml: 1,
                          }}
                        >
                          {itm.label}{" "}
                          {data.blocks[blockIndex].fieldMapping[itm.id]
                            .required && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </Typography>

                        <Autocomplete
                          sx={{
                            flex: 1,
                            mr: "10px",
                            display: "inline-block",
                            borderRadius: "4px",
                            minWidth: 170,
                          }}
                          // {...(for_settings ?{}:{} )}
                          options={
                            fetchedModules[
                              for_settings
                                ? data.moduleName?.api_name
                                : module_for_buttons.api_name
                            ]?.filter((field) => {
                              //if field.api_name is found in selected_fields_in_all_blocks then we have to filter that field
                              if (
                                data.selected_fields_in_all_blocks[
                                  field.api_name
                                ]
                              ) {
                                return false;
                              } else {
                                return true;
                              }
                            }) || []
                          }
                          getOptionLabel={(option) => option.field_label}
                          id="controlled-demo"
                          // disableClearable
                          // popupIcon={<ExpandMoreIcon />}
                          value={
                            data.blocks[blockIndex].fieldMapping[itm.id]
                              .selected_field
                          }
                          onChange={(event, newValue, reason, details) => {
                            const id = itm.id;
                            const prev_api_name =
                              data.blocks[blockIndex].fieldMapping[id]
                                ?.selected_field?.api_name;
                            const new_api_name = newValue?.api_name;

                            // if prev_api_name is null and new_api_name has value then we have to push the new_api_name to selected_fields_in_all_blocks
                            if (!prev_api_name && new_api_name) {
                              setData(
                                produce((draft) => {
                                  draft.selected_fields_in_all_blocks[
                                    new_api_name
                                  ] = new_api_name;
                                })
                              );
                            }
                            // if prev_api_name has value and new_api_name is null then we have to remove prev_api_name from selected_fields_in_all_blocks
                            if (prev_api_name && !new_api_name) {
                              setData(
                                produce((draft) => {
                                  delete draft.selected_fields_in_all_blocks[
                                    prev_api_name
                                  ];
                                })
                              );
                            }
                            // if prev_api_name and new_api_name have value then we have to replace the prev_api_name with the new_api_name from selected_fields_in_all_blocks
                            if (prev_api_name && new_api_name) {
                              setData(
                                produce((draft) => {
                                  delete draft.selected_fields_in_all_blocks[
                                    prev_api_name
                                  ];
                                  draft.selected_fields_in_all_blocks[
                                    new_api_name
                                  ] = new_api_name;
                                })
                              );
                            }

                            if (reason === "clear") {
                              setData(
                                produce((draft) => {
                                  draft.blocks[blockIndex].fieldMapping[
                                    id
                                  ].selected_field = null;
                                })
                              );
                              return;
                            }
                            setData(
                              produce((draft) => {
                                draft.blocks[blockIndex].fieldMapping[
                                  id
                                ].selected_field = {
                                  api_name: newValue.api_name,
                                  field_label: newValue.field_label,
                                };
                              })
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              sx={{
                                "& .MuiFormLabel-root": {
                                  fontWeight: 400,
                                  fontSize: "14px ",
                                  LineHeight: "24px ",
                                  letterSpacing: ".15px ",
                                  color: "rgba(0, 0, 0, 0.6)",
                                },
                              }}
                              error={
                                !data.blocks[blockIndex].fieldMapping[itm.id]
                                  .selected_field &&
                                data.blocks[blockIndex].fieldMapping[itm.id]
                                  .required
                                  ? "true"
                                  : ""
                              }
                              {...params}
                              label=""
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />

                        <Box sx={{ flex: 3 }}>
                          <CustomAutoComplete
                            allowedTypes={itm.allowedTypes}
                            data_type={itm.data_type}
                            fields={fields}
                            defaultValue={
                              data.blocks[blockIndex].fieldMapping[itm.id]
                                .google_response
                            }
                            style={{ width: "100%" }}
                            popoverHeight={300}
                            popoverWidth={280}
                            label=""
                            index={itmIndex}
                            searchableFieldName={"module_label"}
                            onChangeText={(value) => {
                              const id = itm.id;
                              setData(
                                produce((draft) => {
                                  draft.blocks[blockIndex].fieldMapping[
                                    id
                                  ].google_response = value;
                                })
                              );
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          width: "100%",
                          pt: "1rem",
                          px: ".75rem",
                          background: "rgba(25, 118, 210, 0.04)",
                        }}
                        xs={3}
                      >
                        {(() => {
                          let str = itm?.google_response;

                          const regex = /\${[\w._]+}/g;
                          const matches = str.match(regex);

                          matches?.forEach((match) => {
                            const regex = /(\w+)\.(\w+)/;
                            const matches = match.match(regex);
                            const first_word = matches[1];
                            const second_word = matches[2];

                            const value =
                              data.address_components_values?.[first_word]?.[
                                second_word
                              ] || "";

                            str = str.replace(
                              new RegExp(`\\${match}`, "g"),
                              value
                            );
                            if (itm.id != "longitude" && itm.id != "latitude") {
                              str = customTrim(str, "-, ");
                            }
                          });
                          return (
                            <TextField
                              inputProps={{ autocomplete: "off" }}
                              disabled
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={str}
                              size="small"
                              sx={{ width: "100%" }}
                            />
                          );
                        })()}
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <ModalForDeleteBlock
        open={open}
        handleClose={handleCloseNestedModal}
        deleteCurrentBlock={() => {
          //delete fields from the selected_fields_in_all_blocks
          const currentBlocksSelectedFields = Object.values(
            data.blocks[currentBlock].fieldMapping
          )
            .map((field) => field?.selected_field?.api_name)
            .filter((field) => (field ? true : false));
          //updated selected_fields_in_all_blocks
          const updated_selected_fields_in_all_blocks = {
            ...data.selected_fields_in_all_blocks,
          };
          currentBlocksSelectedFields.forEach((field_api_name) => {
            if (data.selected_fields_in_all_blocks[field_api_name]) {
              //delete field from updated_selected_fields_in_all_blocks
              delete updated_selected_fields_in_all_blocks[field_api_name];
            }
          });

          setData(
            produce((draft) => {
              draft.blocks.splice(currentBlock, 1);
              draft.selected_fields_in_all_blocks =
                updated_selected_fields_in_all_blocks;
            })
          );
        }}
      />
      <Grid
        container
        spacing={1}
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          py: 1,
          pr: 2.5,
          backgroundColor: "#ffffff",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          "& .MuiGrid-item": { pt: 0 },
        }}
      >
        <Grid item>
          <Button
            sx={{ width: 100 }}
            size="small"
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <LoadingButton
            loading={loading}
            variant="contained"
            sx={{ width: 100 }}
            loadingPosition="start"
            size="small"
            // startIcon={<SaveIcon />}
            onClick={async () => {
              const errorFound = isErrorFound();

              if (errorFound) {
                handleSnackBarOpen();
                return;
              }

              //if there is existiong data then we have to update the record
              //else we will create a new record
              if (existingData) {
                setLoading(true);
                try {
                  const updateData = await axios.request({
                    url: `https://api.easy-pluginz.com/admin/v2/db/planetscale/clients/settings`,
                    method: "POST",
                    data: {
                      settingId: existingData.setting_id,
                      settingSchema: data,
                      showInRelatedList: false,
                      moduleApiName: data.moduleName.api_name,
                      settingType: "button",
                    },
                    headers: {
                      orgid: orgId, // Org ID
                      apikey: zohoApiKey, // API KEy
                      connname: "easyaddressautocomplete", // Conn Name
                    },
                  });

                  //if succesfully updated then upadte the state
                  if (updateData.status === 200) {
                    handleUpdateSettings();
                    handleClose();
                  } else {
                    alert(JSON.stringify(updateData));
                  }
                } catch (err) {
                  alert(err.message);
                } finally {
                  setLoading(false);
                }
                return;
              }

              try {
                setLoading(true);

                const insertedData = await axios.request({
                  url: `https://api.easy-pluginz.com/admin/v2/db/planetscale/clients/settings`,
                  method: "POST",
                  data: {
                    settingId: "",
                    settingSchema: for_settings
                      ? data
                      : {
                          ...data,
                          moduleName: {
                            api_name: module_for_buttons.api_name,
                            plural_label: module_for_buttons.plural_label,
                          },
                        },
                    showInRelatedList: false,
                    moduleApiName: for_settings
                      ? data.moduleName.api_name
                      : module_for_buttons.api_name,
                    settingType: "button",
                  },
                  headers: {
                    orgid: orgId, // Org ID
                    apikey: zohoApiKey, // API KEy
                    connname: "easyaddressautocomplete", // Conn Name
                  },
                });
                if (insertedData.status === 200) {
                  handleUpdateSettings();
                  handleClose();
                } else {
                  alert(JSON.stringify(insertedData));
                }
              } catch (err) {
                alert(err.message);
              } finally {
                setLoading(false);
              }
            }}
          >
            <span>Save</span>
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Index;
